<template>
    <v-container
        id="start-products-instrum"
        tag="section"
    >
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Стартовые наборы
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Стартовые наборы - информация про варианты стартов в компании LR
                      </p>
                      <p class="mb-5">
                        Цель - Продать преимущества бизнес и профессионального старта. Отправлять только после личного рассказа про старты.
                      </p>
                      <p class="mb-10">
                        <a href="https://teletype.in/@starbuilding/HfeSqMpVHjo" target="_blank">Инструкция по использованию</a>
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                            Ссылка на стартовые наборы
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <a
                                            color="success"
                                            href="https://freestyle21.com/start/"
                                            target="_blank"
                                        >
                                            Стартовые наборы
                                        </a>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Ссылка на стартовые наборы</strong>:
                                        <v-text-field
                                            value="https://freestyle21.com/start/"
                                            readonly
                                            id="linkStarts"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyLinkStarts"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    name: "StartProducts",
    data: () => ({        
        
    }),
    computed: {
        ...mapGetters({
            profile: 'user/profile',
        }),
        profileData() {
            return this.profile
        },        
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            updateProfile: 'user/updateProfile',
        }),
        copyLinkStarts () {
            let linkStarts = document.getElementById('linkStarts')

            linkStarts.select()

            document.execCommand('copy')
        },
    },
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.ul-price
    list-style-type: none

.ul-price li
    padding-bottom: 10px
    line-height: 26px !important

.viz-disign-div
    margin-top: 20px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0
</style>
